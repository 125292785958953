@mixin button-icon($background, $length: 1.2rem) {
  background-image: $background !important;
  background-size: $length $length !important;
  @apply bg-no-repeat
  bg-center
  #{!important};
}

@mixin button-icon-no-background($background, $length) {
  background: none !important;
  background-image: $background !important;
  background-size: $length $length !important;
  @apply bg-no-repeat
  bg-center
  #{!important};
}

.html-editor {
  ::-webkit-scrollbar-thumb {
    @apply shadow-scroll-white;
  }

  .cke_chrome {
    @apply border-none;
  }

  .cke_top {
    @apply bg-gray-75
    rounded
    h-11
    #{!important};

    @apply flex
    items-center
    justify-center
    border-b-gray-100;

    span {
      @apply text-gray-950
      #{!important};
    }

    a {
      @apply mx-1;
    }
  }

  &--topless.html-editor,
  &--topless.html-editor.textarea {
    .cke_top {
      display: none #{!important};
    }
  }

  .cke_inner {
    @apply bg-transparent
    #{!important};
  }

  .cke_combo_on {
    @apply mx-1;

    &:hover {
      @apply mx-0;
    }
  }

  .cke_combo_button {
    @apply mx-1;

    &:hover {
      @apply border-gray-50
      bg-gray-200
      mx-1
      rounded
      #{!important};
    }
  }

  .cke_button:not(.cke_button_disabled) {
    &:hover {
      @apply border-gray-50
      bg-gray-200
      rounded
      #{!important};
    }
  }

  .cke_button_on,
  .cke_combo_on {
    @apply border-gray-50
    bg-gray-50
    rounded
    #{!important};
  }

  .cke_combo_arrow {
    @apply border-t-gray-950;
  }

  // Icons
  .cke_ltr {
    .cke_button__bold_icon {
      @include button-icon(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/bold-light.png),
        1.2rem
      );
    }

    .cke_button__italic_icon {
      @include button-icon(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/italic-light.png),
        1.2rem
      );
    }

    .cke_button__link_icon {
      @include button-icon(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/link-light.png),
        1.2rem
      );
    }

    .cke_button__removeformat_icon {
      @include button-icon(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/remove-format-light.png),
        1.2rem
      );
    }

    .cke_button__undo_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/undo-light.png),
        1.1rem
      );
    }

    .cke_button__redo_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/redo-light.png),
        1.1rem
      );
    }

    .cke_button__bulletedlist_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/bullet-list-light.png),
        1.1rem
      );
    }

    .cke_button__numberedlist_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/numbered-list-light.png),
        1.1rem
      );
    }

    .cke_button__source_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/source-light.png),
        1.1rem
      );
    }
  }

  &.textarea {
    .cke_top {
      @apply flex
      items-center
      justify-start
      border-b-0
      #{!important};

      a {
        @apply mx-0
        #{!important};
      }
    }

    .cke_wysiwyg_frame,
    .cke_wysiwyg_div {
      @apply bg-gray-75;
    }
  }

  .cke_bottom {
    @apply bg-gray-75
    border-none
    #{!important};
  }

  .cke_path_item {
    @apply text-gray-500
    font-sans;
  }
}

.cke_autocomplete_panel,
.cke_autocomplete_opened {
  @apply bg-white
  border-none
  shadow-xl
  p-2
  text-gray-950
  font-sans
  w-fit
  max-w-[18.5rem]
  #{!important};

  li {
    @apply p-3
    rounded
    #{!important};

    &:hover {
      @apply bg-gray-800/[0.04]
      #{!important};
    }
  }

  .cke_autocomplete_selected {
    @apply bg-gray-800/[0.04]
    #{!important};
  }
}

.cke_dialog {
  .cke_dialog_title {
    @apply bg-gray-50
    text-gray-950
    text-17px
    font-sans
    pt-7
    pb-4
    px-6
    font-normal
    border-b-gray-500
    #{!important};
  }

  .cke_dialog_close_button {
    @include button-icon-no-background(
      url(https://static.app.radiantsecurity.ai/images/rte/icons/close-light.png),
      1rem
    );
  }

  .cke_dialog_contents_body {
    @apply bg-gray-50
    text-gray-950
    font-sans
    #{!important};

    label {
      @apply text-gray-950
      font-sans
      #{!important};
    }
  }

  .cke_dialog_footer {
    @apply bg-gray-50
    border-t-gray-50
    py-3
    #{!important};
  }

  input,
  select {
    @apply h-9
    bg-white
    text-white
    font-sans
    rounded
    mt-1
    outline-none
    border-gray-950/[.25]
    focus:border-black
    #{!important};
  }

  .cke_dialog_ui_button_cancel,
  .cke_dialog_ui_button_ok {
    @apply py-2
    px-4
    font-sans
    rounded
    #{!important};
  }

  .cke_dialog_ui_button_cancel {
    @apply bg-transparent
    transition-colors
    duration-200
    ease-in-out
    border-gray-200
    text-gray-950
    #{!important};
  }

  .cke_dialog_ui_button_ok {
    @apply bg-gray-900
    border-gray-900
    text-white
    #{!important};
  }
}

body.dark {
  .html-editor {
    .cke_chrome {
      @apply border-none
      #{!important};
    }

    .cke_top {
      @apply bg-gray-800
      rounded
      #{!important};

      @apply border-b-white/[.25];

      span {
        @apply text-white
        #{!important};
      }
    }

    &--topless.html-editor,
    &--topless.html-editor.textarea {
      .cke_top {
        display: none #{!important};
      }
    }

    .cke_inner {
      @apply bg-transparent
      #{!important};
    }

    .cke_combo_button {
      &:hover {
        @apply border-gray-925
        bg-gray-925
        #{!important};
      }
    }

    .cke_button:not(.cke_button_disabled) {
      &:hover {
        @apply border-gray-925
        bg-gray-925
        #{!important};
      }
    }

    .cke_button_on,
    .cke_combo_on {
      @apply border-gray-925
      bg-gray-925
      #{!important};
    }

    .cke_combo_arrow {
      @apply border-t-white;
    }

    // Icons
    .cke_button__bold_icon {
      @include button-icon(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/bold-dark.png),
        1.2rem
      );
    }

    .cke_button__italic_icon {
      @include button-icon(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/italic-dark.png),
        1.2rem
      );
    }

    .cke_button__link_icon {
      @include button-icon(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/link-dark.png),
        1.2rem
      );
    }

    .cke_button__removeformat_icon {
      @include button-icon(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/remove-format-dark.png),
        1.2rem
      );
    }

    .cke_button__undo_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/undo-dark.png),
        1.1rem
      );
    }

    .cke_button__redo_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/redo-dark.png),
        1.1rem
      );
    }

    .cke_button__bulletedlist_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/bullet-list-dark.png),
        1.1rem
      );
    }

    .cke_button__numberedlist_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/numbered-list-dark.png),
        1.1rem
      );
    }

    .cke_button__source_icon {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/source-dark.png),
        1.1rem
      );
    }

    &.textarea {
      .cke_top {
        @apply flex
        items-center
        justify-start
        #{!important};

        a {
          @apply mx-0
          #{!important};
        }
      }

      .cke_wysiwyg_frame,
      .cke_wysiwyg_div {
        @apply bg-gray-800;
      }
    }

    .cke_bottom {
      @apply bg-gray-800
      border-none
      #{!important};
    }
  }

  .cke_autocomplete_panel,
  .cke_autocomplete_opened {
    @apply bg-gray-900
    border-none
    shadow-sm
    text-gray-50
    font-sans
    #{!important};

    li {
      &:hover {
        @apply bg-white/[0.06]
        #{!important};
      }
    }

    .cke_autocomplete_selected {
      @apply bg-white/[0.06]
      #{!important};
    }
  }

  .cke_dialog {
    .cke_dialog_title {
      @apply bg-gray-925
      text-white
      #{!important};
    }

    .cke_dialog_close_button {
      @include button-icon-no-background(
        url(https://static.app.radiantsecurity.ai/images/rte/icons/close-dark.png),
        1rem
      );
    }

    .cke_dialog_contents_body {
      @apply bg-gray-925
      text-white
      font-sans
      #{!important};

      label {
        @apply text-white
        font-sans
        #{!important};
      }
    }

    .cke_dialog_footer {
      @apply bg-gray-925
      border-t-gray-925
      #{!important};
    }

    input,
    select {
      @apply h-9
      bg-white/[.06]
      text-white
      font-sans
      rounded
      mt-1
      outline-none
      border-white/[.25]
      focus:border-white
      #{!important};
    }

    .cke_dialog_ui_button_cancel {
      @apply border-gray-700
      text-white
      #{!important};
    }

    .cke_dialog_ui_button_ok {
      @apply bg-gray-100
      border-gray-100
      text-gray-950
      #{!important};
    }
  }
}
