.bottomShadow {
  box-shadow: 3px 3px 10px 0px rgba(31, 36, 159, 0.06);
  border-bottom: 1px solid rgba(215, 219, 234, 1);
}
:global(.dark) {
  .bottomShadow {
    box-shadow: 3px 3px 10px 0px rgba(1, 1, 12, 0.06);
    border-bottom: 1px solid rgba(48, 52, 67, 1);
  }
}
