/*
  NOTE: only add CSS here if it's extremely important, otherwise
  use the classes of TailwindCSS directly on your component
*/

::-webkit-scrollbar-corner {
  @apply bg-[rgba(0,0,0,0)];
}

body {
  @apply overflow-hidden
  bg-gray-50 
    min-h-screen;

  &.dark {
    @apply bg-gray-950;
  }
}

::-webkit-scrollbar {
  @apply w-[1.125rem] h-[1.125rem];
}

::-webkit-scrollbar-track {
  @apply border-8
  border-solid
  border-transparent
  shadow-[0_0_1.25rem_1.25rem_rgba(0,0,0,0)];
}

::-webkit-scrollbar-thumb {
  @apply rounded-xl
  border-[0.375rem]
  border-solid
  border-transparent
  shadow-scroll-white
  dark:shadow-scroll-dark;
}

.react-loading-skeleton {
  @apply leading-6;
}

[aria-live='polite'] {
  @apply leading-0;
}
