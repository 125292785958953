.inputCheckboxComposed {
  &:checked {
    @apply bg-gray-925;
  }

  &:checked + label {
    @apply text-white;
  }

  &:checked ~ svg {
    @apply fill-white;
  }

  &Ghost {
    &:checked {
      @apply bg-transparent;
    }

    &:checked + label {
      @apply text-gray-950;
    }

    &:checked ~ svg {
      @apply fill-green-600;
    }
  }
}

:global(.dark) {
  .inputCheckboxComposed {
    &:checked {
      @apply bg-white;
    }

    &:checked + label {
      @apply text-gray-950;
    }

    &:checked ~ svg {
      @apply fill-gray-950;
    }

    &Ghost {
      &:checked {
        @apply bg-transparent;
      }

      &:checked + label {
        @apply text-white;
      }

      &:checked ~ svg {
        @apply fill-green-500;
      }
    }
  }
}
