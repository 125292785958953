.inputSelectItemHover {
  @apply relative;

  &:before {
    @apply absolute
      content-['']
      h-[calc(100%+1rem)]
      w-[calc(100%+1rem)]
      -top-2
      -left-[0.625rem]
      rounded
      bg-gray-800/[.06]
      invisible
      opacity-0
      transition-opacity 
      duration-100 
      ease-in-out;
  }

  &:hover {
    @apply z-20;

    &:before {
      @apply -z-[1]
        visible
        opacity-100;
    }
  }
}

:global(.dark) {
  .inputSelectItemHover {
    &:before {
      @apply bg-white/[.06];
    }
  }
}
