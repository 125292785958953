.radioGroup {
  @apply flex
    flex-col
    overflow-hidden;

  input {
    @apply opacity-0
      top-0
      left-0
      absolute
      w-full
      h-full
      cursor-pointer;
  }

  .radio {
    @apply cursor-pointer
      appearance-none
      bg-transparent
      m-0
      text-gray-200
      w-5
      h-5
      border-2
      border-gray-950/25
      rounded-full
      -translate-y-[0.075rem]
      grid
      place-content-center
      transition-colors
      ease-in-out
      duration-200;

    &::before {
      @apply content-['']
      w-3
      h-3
      rounded-full
      scale-0
      transition-transform
      ease-in-out
      duration-150
      bg-gray-950/25;
    }

    &Active {
      @apply border-gray-950
        #{!important};

      &::before {
        @apply bg-gray-950
        scale-100;
      }
    }
  }
}

:global(.dark) {
  .radioGroup {
    .radio {
      @apply border-white/25;

      &Active {
        @apply border-white
          #{!important};
      }

      &::before {
        @apply bg-white;
      }
    }
  }
}
