.navigatorXBefore {
  &:before {
    content: '';
    @apply w-full 
      h-0.5 
      absolute
      bottom-0 
      left-0 
      z-0
      bg-gray-100
      dark:bg-gray-800;
  }
}

:global(.dark) {
  .navigatorXBefore {
    &:before {
      @apply bg-gray-800;
    }
  }
}
