.inputText {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    @apply appearance-none;
  }

  input {
    @apply moz-appearance-none;
  }

  &:focus-within {
    .inputTextLabel {
      @apply text-black;
    }
  }
}

:global(.dark) {
  .inputText {
    &:focus-within {
      .inputTextLabel {
        @apply text-white;
      }
    }
  }
}
