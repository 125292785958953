button.textLink {
  @apply leading-4.5;
}

.textLinkUnderline {
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
  }

  &WithArrow {
    &:before {
      width: calc(100% - 20px);
    }
  }

  &WithArrowLeft {
    &:before {
      left: auto;
      right: 0;
    }
  }

  &:hover {
    &:before {
      @apply bg-gray-500;
    }
  }
}
