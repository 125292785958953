.json-view {
  color: #4d4d4d !important;

  .json-view--property {
    color: #ed6363 !important;
  }

  .json-view--index {
    color: #676dff !important;
  }

  .json-view--number,
  .json-view--string,
  .json-view--boolean,
  .json-view--null {
    color: #2fcd94 !important;
  }
}

.highlight-log {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.highlight-log::before {
  @apply bg-black/[0.1] dark:bg-black/[0.2];
  content: '';
  position: absolute;
  top: 0;
  left: -100vw;
  right: -100vw;
  height: 100%;
  z-index: -1;
  border-radius: 5px;
}
