.__react_component_tooltip {
  @apply max-w-[16.75rem]
    text-12px
    p-2
    bg-gray-800
    dark:bg-white
    text-gray-50
    dark:text-gray-950
    break-words #{!important};

  &.show {
    @apply opacity-100 #{!important};
  }
}
