.boxKeyValue {
  @apply relative;

  &:before {
    content: '';
    @apply absolute
      h-[calc(100%+1.5rem)]
      w-[calc(100%+2rem)]
      -top-3
      -left-4
      rounded
      bg-gray-800/[.06]
      invisible
      opacity-0
      transition-all 
      duration-100 
      ease-in-out;
  }

  &:hover {
    @apply z-10;

    &:before {
      @apply -z-[1]
        visible
        opacity-100;
    }
  }
}

:global(.dark) {
  .boxKeyValue {
    &:before {
      @apply bg-white/[.06];
    }
  }
}
