:global(.dark) {
  .buttonGhostComposed {
    :global(.react-loading-skeleton) {
      @apply bg-gray-700 #{!important};

      &:after {
        @apply bg-card-skeleton-loader-dark #{!important};
      }
    }
  }
}
