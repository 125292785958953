@mixin loader {
  &::after {
    @apply w-4/6
      h-4/6
      rounded-full
      m-auto
      absolute
      top-0
      left-0
      bottom-0
      right-0;
  }
}

.loaderSpinner {
  &Gray {
    @include loader;

    background: conic-gradient(
      from 90deg at 50% 50%,
      #898ea3 0deg,
      rgba(148, 152, 171, 0) 0.04deg,
      #969bac 360deg
    );
  }

  &Green {
    @include loader;

    background: conic-gradient(
      from 90deg at 50% 50%,
      rgba(0, 216, 185, 0) 0deg,
      #00d8b9 360deg
    );
  }

  &Red {
    @include loader;

    background: conic-gradient(
      from 90deg at 50% 50%,
      rgba(0, 216, 185, 0) 0deg,
      #ff4e86 360deg
    );
  }

  &White {
    @include loader;

    background: conic-gradient(
      from 90deg at 50% 50%,
      rgba(0, 216, 185, 0) 0deg,
      #ffffff 360deg
    );
  }
}
