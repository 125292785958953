.dropdownText {
  svg {
    @apply fill-gray-500
      transition
      duration-200
      ease-in-out;
  }

  &:hover {
    svg {
      @apply fill-gray-950;
    }
  }
}

:global(.dark) {
  .dropdownText {
    &:hover {
      svg {
        @apply fill-white;
      }
    }
  }
}

.dropdownHoverActive {
  @apply relative cursor-pointer;

  &:before {
    @apply absolute
      content-['']
      h-[calc(100%+1.5em)]
      w-[calc(100%+2rem)]
      -top-3
      -left-4
      rounded
      bg-gray-800/[.06]
      invisible
      opacity-0
      transition-all 
      duration-200 
      ease-in-out;
  }

  &:hover {
    @apply z-10;

    &:before {
      @apply -z-[1]
        visible
        opacity-100;
    }
  }
}

.dropdownActive {
  @apply relative z-10;

  &:before {
    @apply absolute
      content-['']
      h-[calc(100%+1.5rem)]
      w-[calc(100%+2rem)]
      -top-3
      -left-4
      rounded
      -z-[1]
      visible
      opacity-100
      bg-gray-800/[.06];
  }
}

:global(.dark) {
  .dropdownActive,
  .dropdownHoverActive {
    &:before {
      @apply bg-white/[.06];
    }
  }
}
