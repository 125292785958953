.dialogRedGradientRight {
  @apply absolute
    h-36
    left-[18.75rem]
    -right-[6.375rem]
    -top-[5.375rem]
    opacity-30
    blur-[17.375rem]
    rotate-[12.25deg]
    pointer-events-none;

  background: linear-gradient(0deg, #ff1560 -0.03%, #ff5d60 99.44%);
}

.dialogRedGradientLeft {
  @apply absolute
    h-20
    -left-6
    right-60
    -top-8
    opacity-30
    blur-[17.375rem]
    pointer-events-none;

  background: linear-gradient(0deg, #ff1560 -0.03%, #ff5d60 99.44%);
}

.dialogGrayGradientRight {
  @apply absolute
    h-36
    left-[18.75rem]
    -right-[6.375rem]
    -top-[5.375rem]
    opacity-30
    blur-[17.375rem]
    rotate-[12.25deg]
    bg-white
    pointer-events-none;
}

.dialogGrayGradientLeft {
  @apply absolute
    h-20
    -left-6
    right-60
    -top-8
    opacity-30
    blur-[17.375rem]
    bg-white
    pointer-events-none;
}

:global(.dark) {
  .dialogGrayGradientRight {
    @apply bg-gray-500;
  }

  .dialogGrayGradientLeft {
    @apply bg-gray-500;
  }
}
