.listItem {
  @apply relative;

  &:before {
    content: '';
    @apply absolute
        -z-10
        h-full
        -left-2
        w-[calc(100%+1rem)]
        rounded
        bg-gray-800/[.06]
        invisible
        opacity-0
        transition-all 
        duration-75 
        ease-in-out;
  }

  &:hover {
    &:before {
      @apply visible
        opacity-100;
    }
  }
}

:global(.dark) {
  .listItem {
    &:before {
      @apply bg-white/[.06];
    }
  }
}
